<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            dark
            @click="openDialog()"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>

          <v-btn
            color="teal"
            class="mr-2"
            outlined
            @click="showDialogDownloadReceipt = true"
          >
            <v-icon
              left
              v-text="'mdi-download'"
            />
            Download
          </v-btn>
          <v-btn
            color="purple"
            class="mr-2"
            outlined
            @click="showDialogContributionDeclaration = true"
          >
            <v-icon
              left
              v-text="'mdi-file-arrow-left-right-outline'"
            />
            Declaração de contribuição
          </v-btn>
          <v-btn
            color="secondary"
            class="mr-2"
            outlined
            @click="showDialogReceiptsSignatures = true"
          >
            <v-icon
              left
              v-text="'mdi-cog'"
            />
            Configurar Assinatura
          </v-btn>
          <v-btn
            color="indigo"
            class="mr-2"
            dark
            outlined
            @click="showDialogDownloadTxt = true"
          >
            <v-icon
              left
              v-text="'mdi-file-document-outline'"
            />
            Download TXT
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="receipts"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadReceipts"
            >
              <template
                v-slot:[`item.commitmentReferenceMonth`]="{ item }"
              >
                {{ item.commitmentReferenceMonth | date('MM/YYYY') }}
              </template>

              <template
                v-slot:[`item.commitmentBillingDate`]="{ item }"
              >
                {{ item.commitmentBillingDate | date }}
              </template>

              <template
                v-slot:[`item.receiptReferenceMonth`]="{ item }"
              >
                {{ item.receiptReferenceMonth | date('MM/YYYY') }}
              </template>

              <template
                v-slot:[`item.receiptSignatureDate`]="{ item }"
              >
                {{ item.receiptSignatureDate | date }}
              </template>

              <template
                v-slot:[`item.searchMonth`]="{ item }"
              >
                {{ item.searchMonth | date('MM/YYYY') }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-receipt
      v-model="showDialogReceipt"
      :receipt-id="editId"
      @save="loadReceipts"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

    <dialog-download-receipt
      v-model="showDialogDownloadReceipt"
    />

    <dialog-contribution-declaration
      v-model="showDialogContributionDeclaration"
    />

    <dialog-receipts-signatures
      v-model="showDialogReceiptsSignatures"
    />

    <dialog-download-txt
      v-model="showDialogDownloadTxt"
    />
  </div>
</template>

<script>
  import receiptsApi from '@/api/receipts'

  export default {

    components: {
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogReceipt: () => import('@/components/dialog/receipts/DialogReceipt'),
      DialogDownloadReceipt: () => import('@/components/dialog/receipts/DialogDownloadReceipt'),
      DialogContributionDeclaration: () => import('@/components/dialog/receipts/DialogContributionDeclaration'),
      DialogReceiptsSignatures: () => import('@/components/dialog/receipts/receipts-signatures/DialogReceiptsSignatures'),
      DialogDownloadTxt: () => import('@/components/dialog/receipts/DialogDownloadTxt'),
    },

    data () {
      return {
        loading: false,
        loadingDelete: false,
        showDialogReceipt: false,
        showDialogDelete: false,
        showDialogDownloadReceipt: false,
        showDialogReceiptsSignatures: false,
        showDialogDownloadTxt: false,
        showDialogContributionDeclaration: false,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Mês Ref. Empenho', value: 'commitmentReferenceMonth' },
          { text: 'Data Cobrança Empenho', value: 'commitmentBillingDate' },
          { text: 'Ano Contribuição Empenho', value: 'commitmentContributionRangeYear' },
          { text: 'Mês Ref. Recibo', value: 'receiptReferenceMonth' },
          { text: 'Data Assinatura Recibo', value: 'receiptSignatureDate' },
          { text: 'Ano Contribuição Recibo', value: 'receiptContributionRangeYear' },
          { text: 'Mês Consulta', value: 'searchMonth' },
          { text: '', value: 'actions', align: 'right' },
        ],
        receipts: [],
        pagination: {},
        editId: null,
        deleteId: null,
      }
    },

    methods: {
      async loadReceipts () {
        try {
          this.loading = true

          const response = await receiptsApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.receipts = response.data.receipts
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (receipt = null) {
        this.editId = receipt ? receipt.id : null
        this.showDialogReceipt = true
      },

      async clickDeleteItem (receipt) {
        this.deleteId = receipt.id
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await receiptsApi.delete(this.deleteId)

          this.loadReceipts()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },

    },

  }
</script>
