var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" Adicionar ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"teal","outlined":""},on:{"click":function($event){_vm.showDialogDownloadReceipt = true}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-download')}}),_vm._v(" Download ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"purple","outlined":""},on:{"click":function($event){_vm.showDialogContributionDeclaration = true}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-file-arrow-left-right-outline')}}),_vm._v(" Declaração de contribuição ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.showDialogReceiptsSignatures = true}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-cog')}}),_vm._v(" Configurar Assinatura ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo","dark":"","outlined":""},on:{"click":function($event){_vm.showDialogDownloadTxt = true}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-file-document-outline')}}),_vm._v(" Download TXT ")],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"disable-sort":"","server-items-length":_vm.count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.receipts,"options":_vm.pagination,"items-per-page":20,"footer-props":{
              'items-per-page-options': [10, 20, 50, 75]
            }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadReceipts]},scopedSlots:_vm._u([{key:"item.commitmentReferenceMonth",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.commitmentReferenceMonth,'MM/YYYY'))+" ")]}},{key:"item.commitmentBillingDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.commitmentBillingDate))+" ")]}},{key:"item.receiptReferenceMonth",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.receiptReferenceMonth,'MM/YYYY'))+" ")]}},{key:"item.receiptSignatureDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.receiptSignatureDate))+" ")]}},{key:"item.searchMonth",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.searchMonth,'MM/YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1),_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')},on:{"click":function($event){return _vm.clickDeleteItem(item)}}})],1)]}}],null,true)})],1)],1)],1)],1),_c('dialog-receipt',{attrs:{"receipt-id":_vm.editId},on:{"save":_vm.loadReceipts},model:{value:(_vm.showDialogReceipt),callback:function ($$v) {_vm.showDialogReceipt=$$v},expression:"showDialogReceipt"}}),_c('dialog-delete',{attrs:{"loading":_vm.loadingDelete},on:{"confirm":_vm.deleteItem},model:{value:(_vm.showDialogDelete),callback:function ($$v) {_vm.showDialogDelete=$$v},expression:"showDialogDelete"}}),_c('dialog-download-receipt',{model:{value:(_vm.showDialogDownloadReceipt),callback:function ($$v) {_vm.showDialogDownloadReceipt=$$v},expression:"showDialogDownloadReceipt"}}),_c('dialog-contribution-declaration',{model:{value:(_vm.showDialogContributionDeclaration),callback:function ($$v) {_vm.showDialogContributionDeclaration=$$v},expression:"showDialogContributionDeclaration"}}),_c('dialog-receipts-signatures',{model:{value:(_vm.showDialogReceiptsSignatures),callback:function ($$v) {_vm.showDialogReceiptsSignatures=$$v},expression:"showDialogReceiptsSignatures"}}),_c('dialog-download-txt',{model:{value:(_vm.showDialogDownloadTxt),callback:function ($$v) {_vm.showDialogDownloadTxt=$$v},expression:"showDialogDownloadTxt"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }